import {Button} from 'primereact/button';
import React from 'react';
import {TwoDialog, TwoToast} from 'two-app-ui';
import Made2QuoteAppContext from '../../../context/Made2QuoteAppContext';
import LocationsService from '../../../services/LocationsService';
import {QueryParameter, Location} from 'two-core';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {InputSwitch, InputSwitchChangeParams} from 'primereact/inputswitch';

interface LocationDropdownOption {
  label: string;
  value: Location;
}

interface Props {
  showDialog: boolean;
  onHide: () => void;
  onSaveEstimateButtonClick: () => void;
  onShippingAddressChange: (location: Location) => void;
}

interface State {
  locationOptions: LocationDropdownOption[];
  selectedLocation?: Location;
  loading: boolean;
  showAll: boolean;
}

export class ShippingAddressDialog extends React.Component<Props, State> {
  static contextType = Made2QuoteAppContext;
  locationsService?: LocationsService;
  twoToast?: TwoToast;

  constructor(props: Props) {
    super(props);
    this.state = {
      locationOptions: [],
      loading: false,
      showAll: false,
    };

    this.loadLocations = this.loadLocations.bind(this);
    this.onSaveEstimateButtonClick = this.onSaveEstimateButtonClick.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onInputShippingAddressChange = this.onInputShippingAddressChange.bind(this);
    this.onShowAllChange = this.onShowAllChange.bind(this);
  }

  componentDidMount() {
    this.twoToast = this.context.twoToast;
    this.locationsService = this.context.locationsService;
  }

  async loadLocations() {
    this.setState({loading: true});
    const {showAll, selectedLocation} = this.state;
    const sortByStringyfied = JSON.stringify({
      field: 'id',
      direction: 'ASC',
    });

    const filters: string[] = [];
    if (!showAll) {
      filters.push(
        JSON.stringify({
          field: 'type',
          value: 'end customer',
          condition: '<>',
        })
      );
    }

    const params: QueryParameter = {
      orderBys: [sortByStringyfied],
      filters: filters,
      aggregate: false,
    };
    return this.locationsService
      ?.getLocations(params)
      .then(data => {
        const locations = data.records as Location[];
        const locationOptions = locations.map(location => {
          return {
            label: `${location.address?.street}, ${location.address?.suburb}, ${location.address?.state}, ${location.address?.country}`,
            value: location,
          };
        });
        const newSelectedLocation = locations.find(location => location.id === selectedLocation?.id);
        this.setState({
          locationOptions: locationOptions,
          selectedLocation: newSelectedLocation,
        });
      })
      .catch(error => {
        console.error('Failed Loading Data:' + error);
        this.twoToast?.showError('Failed loading data, please refresh and try again.');
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  onShow() {
    this.setState(
      {
        showAll: false,
      },
      () => this.loadLocations()
    );
  }

  onInputShippingAddressChange(e: DropdownChangeParams) {
    const {onShippingAddressChange} = this.props;
    const value = e.value as Location;
    onShippingAddressChange(value);
    this.setState({selectedLocation: value});
  }

  onShowAllChange(e: InputSwitchChangeParams) {
    const value = e.value;
    this.setState({showAll: value}, () => this.loadLocations());
  }

  onSaveEstimateButtonClick() {
    const {selectedLocation} = this.state;
    const {onSaveEstimateButtonClick} = this.props;
    if (selectedLocation) {
      onSaveEstimateButtonClick();
    } else {
      this.twoToast?.showError('Shipping address field is empty.');
    }
  }

  render() {
    const {onHide, showDialog} = this.props;
    const {locationOptions, selectedLocation, showAll, loading} = this.state;
    const footer = (
      <>
        <Button label="Close" className={'p-mr-2 p-button-text'} onClick={onHide} />
        <Button label="Save Estimate" className={'p-mr-2'} onClick={this.onSaveEstimateButtonClick} />
      </>
    );
    const dialogBody = (
      <div className="p-fluid w-100 p-mx-2">
        <div className="p-field p-grid p-mb-0">
          <div className="p-sm-12 p-md-7 p-lg-9">
            <Dropdown
              placeholder="Select shipping address"
              value={selectedLocation}
              options={locationOptions}
              onChange={this.onInputShippingAddressChange}
              dataKey="id"
              filter={locationOptions.length > 4}
              filterBy="label"
            />
          </div>
          <div className="p-d-flex p-sm-12 p-md-5 p-lg-3 p-as-center">
            <label className="p-ml-auto p-as-center">
              <small>One-offs</small>
            </label>
            <InputSwitch className="p-ml-2" name="showAll" checked={showAll} onChange={this.onShowAllChange} />
          </div>
        </div>
      </div>
    );

    return (
      <TwoDialog
        header="Choose shipping address"
        loading={loading}
        onHide={onHide}
        onShow={this.onShow}
        showDialog={showDialog}
        footer={footer}
        style={{width: '50vw'}}
        breakpoints={{'768px': '75vw', '576px': '90vw'}}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
