import React from 'react';
import Made2QuoteAppContext from '../../../context/Made2QuoteAppContext';
import {MessageService, OrderItemsComponent, TwoSpeedDial, TwoSpeedDialItem, TwoToast} from 'two-app-ui';
import {messages} from '../../../config/messages';
import {
  ApiListResponse,
  Draft,
  Field,
  OrderItem,
  OrderStage,
  DraftPatch,
  PaProductDefinition,
  QueryParameter,
  PaOrder,
  LocationReference,
  Location,
  PaContact,
} from 'two-core';
import DraftsService from '../../../services/DraftsService';
import {ProgressSpinner} from 'primereact/progressspinner';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import ProductsService from '../../../services/ProductsService';
import {InputText} from 'primereact/inputtext';
import './NewEditDraftForm.scss';
import {ScrollPanel} from 'primereact/scrollpanel';
import {ValidationDialog} from './ValidationDialog';
import OrdersService from '../../../services/OrdersService';
import {ShippingAddressDialog} from './ShippingAddressDialog';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import ContactsService from '../../../services/ContactsService';
import M2QUsersService from '../../../services/M2QUsersService';
import {config} from '../../../config/config';
import {InputTextarea} from 'primereact/inputtextarea';
import {
  faArrowsMaximize,
  faArrowsMinimize,
  faBackward,
  faCheck,
  faFloppyDisk,
  faForward,
  faPaperPlane,
} from '@fortawesome/pro-regular-svg-icons';
import {MenuItem} from 'primereact/menuitem';
import {NewProductDefinitionDialog} from './NewProductDefinitionDialog';
import {RevertCurrentDefinitionDialog} from './RevertCurrentDefinitionDialog';

interface Props {
  id: string;
}

interface State {
  draft?: Draft;
  loading: boolean;
  draftPatch: DraftPatch;
  contacts: PaContact[];
  selectedOwner?: PaContact;
  orderProductDefinitions: PaProductDefinition[];
  currentProductDefinitions: PaProductDefinition[];
  clonedItems: OrderItem[];
  invalidItemMessagesMap?: Map<number, string[]>;
  validationDialogTitle: string;
  validationContext: JSX.Element | string;
  showValidationDialog: boolean;
  showShippingAddressDialog: boolean;
  showNewProductDefinitionDialog: boolean;
  showRevertCurrentDefinitionDialog: boolean;
  shippingAddressRef?: LocationReference;
  collapsedItemIndexes: Set<number>;
  savingDraft: boolean;
  savingIntoFF2: boolean;
  forceCurrentProductDefinition: boolean;
}

class NewEditDraftForm extends React.Component<RouteComponentProps<Props>, State> {
  static contextType = Made2QuoteAppContext;

  usersService?: M2QUsersService;
  ordersService?: OrdersService;
  draftsService?: DraftsService;
  twoToast?: TwoToast;
  productsService?: ProductsService;
  contactsService?: ContactsService;
  orderItemsRef?: React.RefObject<OrderItemsComponent>;

  constructor(props: RouteComponentProps<Props>) {
    super(props);
    this.state = {
      clonedItems: [],
      invalidItemMessagesMap: new Map<number, string[]>(),
      loading: false,
      contacts: [],
      draftPatch: {},
      orderProductDefinitions: [],
      currentProductDefinitions: [],
      validationDialogTitle: '',
      validationContext: '',
      showValidationDialog: false,
      showShippingAddressDialog: false,
      showNewProductDefinitionDialog: false,
      showRevertCurrentDefinitionDialog: false,
      collapsedItemIndexes: new Set<number>(),
      savingDraft: false,
      savingIntoFF2: false,
      forceCurrentProductDefinition: false,
    };

    this.onReferenceChange = this.onReferenceChange.bind(this);
    this.onNoteChange = this.onNoteChange.bind(this);
    this.onItemsChange = this.onItemsChange.bind(this);
    this.onInputOwnerChange = this.onInputOwnerChange.bind(this);
    this.contactValueTemplate = this.contactValueTemplate.bind(this);
    this.contactOptionTemplate = this.contactOptionTemplate.bind(this);
    this.setInvalidItemMessagesMap = this.setInvalidItemMessagesMap.bind(this);
    this.cloneItems = this.cloneItems.bind(this);
    this.validate = this.validate.bind(this);
    this.createDraft = this.createDraft.bind(this);
    this.updateDraft = this.updateDraft.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.onSaveDraftButtonClick = this.onSaveDraftButtonClick.bind(this);
    this.onSendToFf2ButtonClick = this.onSendToFf2ButtonClick.bind(this);
    this.onSaveEstimate = this.onSaveEstimate.bind(this);
    this.onValidationDialogHide = this.onValidationDialogHide.bind(this);
    this.onValidateAllButtonClick = this.onValidateAllButtonClick.bind(this);
    this.onShippingAddressDialogHide = this.onShippingAddressDialogHide.bind(this);
    this.onShippingAddressChange = this.onShippingAddressChange.bind(this);
    this.onItemToggle = this.onItemToggle.bind(this);
    this.onItemsCollapse = this.onItemsCollapse.bind(this);
    this.onItemsExpand = this.onItemsExpand.bind(this);
    this.onRevertCurrentDefinitionClick = this.onRevertCurrentDefinitionClick.bind(this);
    this.getSpeedDialItems = this.getSpeedDialItems.bind(this);
    this.onNewProductDefinitionDialogCancel = this.onNewProductDefinitionDialogCancel.bind(this);
    this.onNewProductDefinitionDialogYes = this.onNewProductDefinitionDialogYes.bind(this);
    this.onRevertCurrentDefinitionDialogNo = this.onRevertCurrentDefinitionDialogNo.bind(this);
    this.onRevertCurrentDefinitionDialogYes = this.onRevertCurrentDefinitionDialogYes.bind(this);
    this.onApplyCurrentDefinitionClick = this.onApplyCurrentDefinitionClick.bind(this);

    this.orderItemsRef = React.createRef();
  }

  async componentDidMount() {
    this.usersService = this.context.usersService;
    this.twoToast = this.context.twoToast;
    this.ordersService = this.context.ordersService;
    this.draftsService = this.context.draftsService;
    this.productsService = this.context.productsService;
    this.contactsService = this.context.contactsService;

    await this.loadData();
    this.loadContacts();
  }

  async loadData() {
    this.setState({loading: true});
    let revisionId = undefined;
    const id = this.props.match.params.id;
    if (id?.length) {
      const draft = await this.loadDraft(id);
      if (draft) {
        revisionId = draft.revision_id;
        MessageService.sendMessage({
          name: messages.draftViewed,
          value: `${draft.id} ${draft.reference}`,
        });
      }
    } else {
      MessageService.sendMessage({
        name: messages.draftViewed,
        value: 'New Draft',
      });
    }
    this.loadOrderProductDefinitions(revisionId)
      .catch(error => {
        console.error('Failed Loading Data:' + error);
        this.twoToast?.showError('Failed loading data, please refresh and try again.');
      })
      .finally(() => {
        this.setState({loading: false});
      });
    this.loadCurrentProductDefinitions()
      .catch(error => {
        console.error('Failed Loading Data:' + error);
        this.twoToast?.showError('Failed loading data, please refresh and try again.');
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  async loadContacts() {
    if (localStorage.getItem(config().keys.current_role) === 'admin') {
      const sortBy = JSON.stringify({
        field: 'first_name',
        direction: 'ASC',
      });

      this.contactsService
        ?.getContacts({orderBys: [sortBy]})
        .then(data => {
          const contacts = (data.records as PaContact[]) ?? [];
          const ownerContactId = this.state.draft?.owner_contact_ref?.contact_id ?? this.usersService?.myContact?.id;
          this.setState({
            contacts: contacts,
            selectedOwner: contacts.find(contact => contact.id === ownerContactId),
          });
        })
        .catch(error => {
          console.log(error);
          this.setState({contacts: []});
        });
    }
  }

  async loadDraft(id: string) {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );
    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    return this.draftsService
      ?.getDrafts(params)
      .then((data: ApiListResponse) => {
        const dataRecords = (data.records as Draft[]) ?? [];
        const draft = dataRecords[0] ?? undefined;
        const clonedItems = this.cloneItems(draft?.items ?? []);
        this.setState({
          draft: draft,
          clonedItems: clonedItems,
        });
        return draft;
      })
      .catch(error => {
        this.twoToast?.showError('Records load failed');
        console.log(error);
      });
  }

  async loadOrderProductDefinitions(revisionId?: number) {
    return this.productsService?.getProductsDefinitions(revisionId).then(data => {
      const productDefinitions = (data.records as PaProductDefinition[]) ?? [];
      this.setState({
        orderProductDefinitions: productDefinitions,
      });
    });
  }

  async loadCurrentProductDefinitions() {
    return this.productsService?.getProductsDefinitions().then(data => {
      const productDefinitions = (data.records as PaProductDefinition[]) ?? [];
      this.setState({
        currentProductDefinitions: productDefinitions,
      });
    });
  }

  setInvalidItemMessagesMap(newInvalidItemMessagesMap: Map<number, string[]>) {
    this.setState({
      invalidItemMessagesMap: newInvalidItemMessagesMap,
    });
  }

  cloneItems(items: OrderItem[]): OrderItem[] {
    const clonedItems = (structuredClone(items) as OrderItem[]).map(item => {
      const fields: Field[] = item.fields.map(field => {
        //we must convert field to class object
        const values = field.values.map(fieldValue => {
          if (fieldValue.sub_fields) {
            fieldValue.sub_fields = fieldValue.sub_fields.map(subField => {
              //we must convert subfield to class object
              return new Field(subField);
            });
          }
          return fieldValue;
        });
        return new Field({...field, values: values});
      });
      item.fields = fields;
      return new OrderItem(item);
    });
    return clonedItems;
  }

  onReferenceChange(e: React.ChangeEvent<HTMLInputElement>) {
    const draftPatch = this.state.draftPatch;
    const value = e.target.value;
    const updatedDraftPatch = {
      ...draftPatch,
      reference: value,
    };
    this.setState({draftPatch: updatedDraftPatch});
  }

  onNoteChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const draftPatch = this.state.draftPatch;
    const value = e.target.value;
    const updatedDraftPatch = {
      ...draftPatch,
      note: value,
    };
    this.setState({draftPatch: updatedDraftPatch});
  }

  onInputOwnerChange = (e: DropdownChangeParams) => {
    this.setState({selectedOwner: e.value});
  };

  contactValueTemplate(contact: PaContact) {
    if (contact) {
      return <div>{`${contact.first_name} ${contact.last_name} [${contact.role}]`}</div>;
    } else {
      return 'empty';
    }
  }

  contactOptionTemplate(contact: PaContact) {
    return <div>{`${contact.first_name} ${contact.last_name} [${contact.role}]`}</div>;
  }

  onItemsChange(newItems: OrderItem[]) {
    const draftPatch = this.state.draftPatch;

    const updatedDraftPatch = {
      ...draftPatch,
      items: newItems,
    };
    this.setState({
      draftPatch: updatedDraftPatch,
      clonedItems: newItems,
    });
  }

  onSaveDraftButtonClick() {
    const {draft} = this.state;
    if (this.validate(false, 'Draft')) {
      if (draft?.id) {
        this.updateDraft(false);
      } else {
        this.createDraft(false);
      }
    }
  }

  onSaveEstimate() {
    const {draft} = this.state;
    this.setState({showShippingAddressDialog: false});
    if (draft?.id) {
      this.updateDraft(true);
    } else {
      this.createDraft(true);
    }
  }

  onSendToFf2ButtonClick() {
    const {orderProductDefinitions, currentProductDefinitions, forceCurrentProductDefinition} = this.state;
    if (this.validate(false)) {
      if (
        !forceCurrentProductDefinition &&
        orderProductDefinitions?.length &&
        currentProductDefinitions?.length &&
        orderProductDefinitions[0].revision_id !== currentProductDefinitions[0].revision_id
      ) {
        this.setState({showNewProductDefinitionDialog: true});
      } else {
        this.setState({showShippingAddressDialog: true});
      }
    }
  }

  onValidateAllButtonClick() {
    this.validate(true);
  }

  async createDraft(createOrder: boolean) {
    this.setState({savingDraft: !createOrder, savingIntoFF2: createOrder});

    const ownerId = localStorage.getItem(config().keys.current_company) ?? ' ';
    const newDraft: Draft = {
      revision_id: this.state.orderProductDefinitions[0].revision_id,
      size: 0,
      owner: ownerId,
      owner_contact_ref: {contact_id: this.state.selectedOwner?.id},
      deleted: false,
      ...this.state.draftPatch,
    } as Draft;
    if (!newDraft.items) {
      newDraft.items = [];
    }

    return this.draftsService
      ?.createDraft(ownerId, newDraft)
      .then(draft => {
        const successStory = `Draft ${draft.id} ${newDraft.reference} created successfully.`;
        this.twoToast?.showSuccess(successStory);
        if (createOrder) {
          this.createOrder(draft, successStory).then(() => {
            this.setState({savingDraft: false, savingIntoFF2: false});
          });
        } else {
          this.setState({savingDraft: false, savingIntoFF2: false});
          this.props.history.push('/drafts');
        }
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, draft creation failed. Please check your internet connection and try again.');
        console.error('error: ' + error);
        this.setState({savingDraft: false, savingIntoFF2: false});
      });
  }

  async updateDraft(createOrder: boolean) {
    this.setState({savingDraft: !createOrder, savingIntoFF2: createOrder});
    const draft = this.state.draft!;
    const draftPatch = {...this.state.draftPatch};
    const ownerId = localStorage.getItem(config().keys.current_company) ?? ' ';
    if (
      this.state.selectedOwner &&
      this.state.selectedOwner.id &&
      this.state.selectedOwner.id !== draft.owner_contact_ref?.contact_id
    ) {
      draftPatch.owner_contact_ref = {
        contact_id: this.state.selectedOwner.id,
      };
    }
    if (!draftPatch.items) {
      draftPatch.items = [];
    }
    if (this.state.forceCurrentProductDefinition) {
      draftPatch.revision_id = this.state.currentProductDefinitions[0].revision_id;
    }
    return this.draftsService
      ?.updateDraft(ownerId, draft.id!, draftPatch)
      .then(draft => {
        const successStory = `Draft ${draft.id} ${draft.reference} updated successfully.`;
        this.twoToast?.showSuccess(successStory);
        if (createOrder) {
          this.createOrder(draft, successStory).then(() => {
            this.setState({savingDraft: false, savingIntoFF2: false});
          });
        } else {
          this.setState({savingDraft: false, savingIntoFF2: false});
          this.props.history.push('/drafts');
        }
      })
      .catch(() => {
        this.twoToast?.showError('Sorry, draft update failed. Please check your internet connection and try again.');
        this.setState({savingDraft: false, savingIntoFF2: false});
      });
  }

  async createOrder(draft: Draft, previosStepResult: string) {
    const {draftPatch, orderProductDefinitions, shippingAddressRef, selectedOwner} = this.state;

    const ownerId = draft?.owner ?? localStorage.getItem(config().keys.current_company) ?? ' ';
    const newOrder: PaOrder = {
      stage: 'Estimate',
      type: 'Standard',
      priority: 1,
      owner: ownerId,
      items: draftPatch.items ?? draft!.items,
      freight_options: {
        freight_type: 'made2freight',
      },
      reference: draftPatch.reference ?? draft!.reference,
      shipping_address: shippingAddressRef as LocationReference,
      revision_id: orderProductDefinitions[0].revision_id,
      draft_id: draft.id,
    };
    if (selectedOwner && selectedOwner.id) {
      newOrder.owner_contact_ref = {
        contact_id: selectedOwner.id,
      };
    }

    return this.ordersService
      ?.createOrder(ownerId, newOrder)
      .then(async (order: PaOrder) => {
        this.twoToast?.showSuccess(`Estimate ${order.id} ${order.reference} created successfully in FF2.`);
        this.setState({savingIntoFF2: false});
        this.props.history.push('/drafts');
      })
      .catch(error => {
        this.twoToast?.showError(
          `${previosStepResult} Sadly, estimate creation into FF2 failed. DevOps have been informed and will follow up soon.`
        );
        console.error('error: ' + error);
      });
  }

  validate(showSuccessfulDialog: boolean, stage?: OrderStage): boolean {
    const {draftPatch, draft} = this.state;
    const {invalidItemMessagesMap} = this.state;

    const errors: string[] = [];
    const itemsErrors = [];
    const reference = draftPatch?.reference ?? draft?.reference ?? '';
    if (!reference?.length) {
      errors.push('Reference field is empty.');
    }
    if (localStorage.getItem(config().keys.current_role) === 'admin' && !this.state.selectedOwner) {
      errors.push('Owner not chosen.');
    }
    if (stage !== 'Draft') {
      const items = draftPatch?.items ?? draft?.items ?? [];
      if (!items?.length) {
        errors.push('Order must have at least one item.');
      }
      if (invalidItemMessagesMap?.size) {
        for (const [itemIndex, messages] of Array.from(invalidItemMessagesMap.entries())) {
          itemsErrors.push(
            <div>
              Item {itemIndex} is invalid:
              {messages.map((error, index) => {
                return <li key={index}>{error}</li>;
              })}
            </div>
          );
        }
      }
    }

    if (errors.length || itemsErrors.length) {
      const errorText = (
        <div>
          {!!errors.length && (
            <div>
              Form is invalid:
              {errors.map((error, index) => {
                return <li key={index}>{error}</li>;
              })}
            </div>
          )}
          {!!itemsErrors.length && itemsErrors}
        </div>
      );
      this.setState({
        validationDialogTitle: 'Validation Failed',
        validationContext: errorText,
        showValidationDialog: true,
      });
      MessageService.sendMessage(messages.orderCanNotBeSaved);
      return false;
    }
    if (showSuccessfulDialog) {
      this.setState({
        validationDialogTitle: 'Validation Successful',
        validationContext: 'All items valid.',
        showValidationDialog: true,
      });
      MessageService.sendMessage(messages.orderCanNotBeSaved);
    }
    return true;
  }

  onValidationDialogHide() {
    this.setState({showValidationDialog: false});
  }

  onShippingAddressDialogHide() {
    this.setState({showShippingAddressDialog: false});
  }

  onNewProductDefinitionDialogCancel() {
    this.setState({showNewProductDefinitionDialog: false});
  }

  onNewProductDefinitionDialogYes() {
    this.setState({
      forceCurrentProductDefinition: true,
      showNewProductDefinitionDialog: false,
    });
  }
  onRevertCurrentDefinitionDialogNo() {
    this.setState({showRevertCurrentDefinitionDialog: false});
  }

  onRevertCurrentDefinitionDialogYes() {
    const {draft} = this.state;
    const clonedItems = this.cloneItems(draft?.items ?? []);
    this.setState({
      clonedItems: clonedItems,
      showRevertCurrentDefinitionDialog: false,
      forceCurrentProductDefinition: false,
    });
  }

  onShippingAddressChange(location: Location) {
    const newAddressRef: LocationReference = {
      address:
        location.address.street +
        ', ' +
        location.address.suburb +
        ', ' +
        location.address.state +
        ', ' +
        location.address.country,
      id: location.id!,
    };
    this.setState({shippingAddressRef: newAddressRef});
  }

  onItemToggle(index: number) {
    const newCollapsedItemIndexes = new Set(this.state.collapsedItemIndexes);
    if (newCollapsedItemIndexes.has(index)) {
      newCollapsedItemIndexes.delete(index);
    } else {
      newCollapsedItemIndexes.add(index);
    }
    this.setState({collapsedItemIndexes: newCollapsedItemIndexes});
  }

  onItemsCollapse() {
    const itemIndexes = this.state.clonedItems.map(item => item.index);
    this.setState({collapsedItemIndexes: new Set<number>(itemIndexes)});
  }

  onItemsExpand() {
    this.setState({collapsedItemIndexes: new Set<number>()});
  }

  onRevertCurrentDefinitionClick() {
    this.setState({showRevertCurrentDefinitionDialog: true});
  }

  onApplyCurrentDefinitionClick() {
    this.setState({forceCurrentProductDefinition: true});
  }

  getSpeedDialItems(): MenuItem[] {
    const {
      clonedItems,
      collapsedItemIndexes,
      orderProductDefinitions,
      currentProductDefinitions,
      forceCurrentProductDefinition,
      draft,
      savingDraft,
      savingIntoFF2,
    } = this.state;
    const readOnly = (draft ? !!draft.order_id : false) || savingDraft || savingIntoFF2;

    const speedDialItems: MenuItem[] = [];
    if (clonedItems.length) {
      const allItemsMinimised = clonedItems.every(item => collapsedItemIndexes.has(item.index));
      if (allItemsMinimised) {
        speedDialItems.push({
          template: <TwoSpeedDialItem icon={faArrowsMaximize} onClick={this.onItemsExpand} label="Expand" />,
        });
      } else {
        speedDialItems.push({
          template: <TwoSpeedDialItem icon={faArrowsMinimize} label="Collapse" onClick={this.onItemsCollapse} />,
        });
      }
    }

    if (!readOnly) {
      if (
        orderProductDefinitions?.length &&
        currentProductDefinitions?.length &&
        orderProductDefinitions[0].revision_id !== currentProductDefinitions[0].revision_id
      ) {
        if (forceCurrentProductDefinition) {
          speedDialItems.push({
            template: (
              <TwoSpeedDialItem
                icon={faBackward}
                label="Revert current definition"
                onClick={this.onRevertCurrentDefinitionClick}
              />
            ),
          });
        } else {
          speedDialItems.push({
            template: (
              <TwoSpeedDialItem
                icon={faForward}
                label="Apply current definition"
                onClick={this.onApplyCurrentDefinitionClick}
              />
            ),
          });
        }
      }
      speedDialItems.push({
        template: <TwoSpeedDialItem icon={faCheck} label="Validate All" onClick={this.onValidateAllButtonClick} />,
      });
      speedDialItems.push({
        template: <TwoSpeedDialItem icon={faFloppyDisk} label="Save Draft" onClick={this.onSaveDraftButtonClick} />,
      });
      speedDialItems.push({
        template: <TwoSpeedDialItem icon={faPaperPlane} label="Send to FF2" onClick={this.onSendToFf2ButtonClick} />,
      });
    }

    return speedDialItems;
  }

  render() {
    const {
      loading,
      draftPatch,
      draft,
      contacts,
      selectedOwner,
      showValidationDialog,
      showShippingAddressDialog,
      validationDialogTitle,
      validationContext,
      collapsedItemIndexes,
      savingDraft,
      savingIntoFF2,
      orderProductDefinitions,
      currentProductDefinitions,
      clonedItems,
      forceCurrentProductDefinition,
      showNewProductDefinitionDialog,
      showRevertCurrentDefinitionDialog,
    } = this.state;

    let formContent;
    const readOnly = (draft ? !!draft.order_id : false) || savingDraft || savingIntoFF2;

    if (loading) {
      formContent = (
        <div className="overlay">
          <ProgressSpinner className="overlay-spinner" />
        </div>
      );
    } else {
      const speedDialItems: MenuItem[] = this.getSpeedDialItems();
      let originalProductDefinitions;
      let productDefinitions;
      if (forceCurrentProductDefinition) {
        originalProductDefinitions = orderProductDefinitions;
        productDefinitions = currentProductDefinitions;
      } else {
        productDefinitions = orderProductDefinitions;
      }

      formContent = (
        <>
          <div className="draft-content p-m-2">
            <ScrollPanel className="draft-scroll-panel">
              <div className="p-fluid">
                <div className="p-field p-grid">
                  <label htmlFor="reference" className="p-col-4">
                    reference
                  </label>
                  <div className="p-col-8">
                    <InputText
                      value={draftPatch?.reference ?? draft?.reference ?? ''}
                      disabled={readOnly}
                      onChange={this.onReferenceChange}
                    />
                  </div>
                </div>
                {contacts.length ? (
                  <div className="p-field p-grid">
                    <label htmlFor="reference" className="p-col-4">
                      owner
                    </label>
                    <div className="p-col-8">
                      <Dropdown
                        id="owner_selector"
                        value={selectedOwner}
                        options={contacts}
                        onChange={this.onInputOwnerChange}
                        dataKey="id"
                        optionLabel="id"
                        valueTemplate={this.contactValueTemplate}
                        itemTemplate={this.contactValueTemplate}
                        disabled={readOnly}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="p-field p-grid">
                  <label htmlFor="reference" className="p-col-4">
                    note
                  </label>
                  <div className="p-col-8">
                    <InputTextarea
                      autoResize
                      value={draftPatch?.note ?? draft?.note ?? ''}
                      onChange={this.onNoteChange}
                      disabled={readOnly}
                    />
                  </div>
                </div>
                <div className="p-field p-grid">
                  {!!orderProductDefinitions.length && (
                    <OrderItemsComponent
                      mode={readOnly ? 'readonly' : 'advanced_edit'}
                      items={clonedItems}
                      productDefinitions={productDefinitions}
                      originalProductDefinitions={originalProductDefinitions}
                      onItemsChanged={this.onItemsChange}
                      setInvalidItemMessagesMap={this.setInvalidItemMessagesMap}
                      outerItemColumnClassName={'p-col-12 p-md-6'}
                      innerItemColumnClassName={'p-col-12'}
                      collapsedItemIndexes={collapsedItemIndexes}
                      onItemToggle={this.onItemToggle}
                      showCopyLastButton
                      ref={this.orderItemsRef}
                    />
                  )}
                </div>
              </div>
            </ScrollPanel>
          </div>
          <TwoSpeedDial model={speedDialItems} />
          <ValidationDialog
            title={validationDialogTitle}
            showDialog={showValidationDialog}
            onHide={this.onValidationDialogHide}
          >
            {validationContext}
          </ValidationDialog>
          <ShippingAddressDialog
            showDialog={showShippingAddressDialog}
            onHide={this.onShippingAddressDialogHide}
            onSaveEstimateButtonClick={this.onSaveEstimate}
            onShippingAddressChange={this.onShippingAddressChange}
          />
          <NewProductDefinitionDialog
            showDialog={showNewProductDefinitionDialog}
            onCancel={this.onNewProductDefinitionDialogCancel}
            onYes={this.onNewProductDefinitionDialogYes}
          />
          <RevertCurrentDefinitionDialog
            showDialog={showRevertCurrentDefinitionDialog}
            onNo={this.onRevertCurrentDefinitionDialogNo}
            onYes={this.onRevertCurrentDefinitionDialogYes}
          />
        </>
      );
    }

    return (
      <div id="new_edit_draft_form">
        {formContent}
        {!!(savingDraft || savingIntoFF2) && (
          <div className="overlay">
            <ProgressSpinner className="overlay-spinner" />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(NewEditDraftForm);
