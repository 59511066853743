import React, {Component, PropsWithChildren} from 'react';
import {AuthenticatedUser, AuthService, defaultTimeZone, Login, MessageService, ResetPassword} from 'two-app-ui';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {PaCompany, PaContact, User} from 'two-core';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Subscription} from 'rxjs';
import {IANAZone, Settings} from 'luxon';
import {Toolbar} from 'primereact/toolbar';
import LeftToolbar from './LeftToolbar';
import RightToolbar from './RightToolbar';
import CompaniesService from '../../services/CompaniesService';
import {messages} from '../../config/messages';
import {config} from '../../config/config';
import './AppFrame.scss';
import Made2QuoteAppContext from '../../context/Made2QuoteAppContext';
import NewEditDraftForm from '../Draft/NewEditDraftFrom/NewEditDraftForm';
import DraftListComponent from '../Drafts/DraftListComponent';
import ContactsService from '../../services/ContactsService';
import M2QUsersService from '../../services/M2QUsersService';

interface State {
  isAuthenticated: boolean;
  loading: boolean;
  currentUser?: User;
  loadingPermission: boolean;
  companies: PaCompany[];
}

export class AppFrame extends Component<PropsWithChildren<{}>, State> {
  static contextType = Made2QuoteAppContext;
  subscription: Subscription = new Subscription();
  authService?: AuthService;
  usersService?: M2QUsersService;
  companiesService?: CompaniesService;
  contactsService?: ContactsService;

  constructor(props: {}) {
    super(props);
    this.state = {
      isAuthenticated: false,
      loading: true,
      loadingPermission: true,
      companies: [],
    };
  }

  async componentDidMount() {
    this.authService = this.context.authService;
    this.usersService = this.context.usersService;
    this.companiesService = this.context.companiesService;
    this.contactsService = this.context.contactsService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.loggedIn) {
        this.onLoggedIn();
      } else if (message === messages.loggedOut) {
        this.onLoggedOut();
      } else if (message === messages.topSelectionChanged) {
        this.onCompanyChanged();
      }
    });

    this.onLoggedIn();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  onLoggedIn() {
    this.authService
      ?.isSessionValid()
      .then(async isAuthenticated => {
        this.loadUser();
        this.loadCompanies();
        this.setState({
          isAuthenticated: isAuthenticated,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({
          isAuthenticated: false,
          loading: false,
        });
        console.error(error);
      });
  }

  onLoggedOut() {
    this.setState({companies: [], isAuthenticated: false});
  }

  onCompanyChanged() {
    const newCompanyId = localStorage.getItem(config().keys.current_company);
    const newCompany = this.state.companies.find(company => company.id === newCompanyId);

    if (this.usersService && newCompany) {
      localStorage.setItem(config().keys.current_role, newCompany.my_role);
    }
  }

  /**
   * Load user aggregation for use in top menu components. E.g.: Show user's application.
   * And also fix empty user's timezone.
   */
  loadUser() {
    const userJsonString = localStorage.getItem(config().keys.user) ?? '{}';
    const authenticatedUser: AuthenticatedUser = JSON.parse(userJsonString);
    // load user only once
    if (authenticatedUser.uuid && !this.state.currentUser) {
      this.contactsService
        ?.getMe()
        .then(data => {
          if (this.usersService) {
            this.usersService.myContact = data as unknown as PaContact;
          }
        })
        .catch(e => {
          console.log('No Contact => Maybe TWO. ' + e);
        });
      this.usersService
        ?.getUsers({
          aggregate: true,
          filters: [
            JSON.stringify({
              field: 'id',
              value: authenticatedUser.uuid,
            }),
          ],
        })
        .then(data => {
          const user = (data.records as User[])[0];
          //save permissions for current app
          const currentAppId = +(process.env.REACT_APP_TWO_ID ?? -1);
          const currentApp = user.applications?.find(app => app.id === currentAppId);
          this.usersService!.permissions = currentApp?.permissions ?? [];
          this.usersService!.unitSystem = user.settings?.unit_system ?? 'metric';
          // Set default zone for luxon.
          // Valid zone is in format: 'Australia/Brisbane'.
          const userDefaultTimezone = user?.settings?.default_timezone ?? '';
          Settings.defaultZone = IANAZone.isValidZone(userDefaultTimezone) ? userDefaultTimezone : defaultTimeZone;
          this.setState({currentUser: user, loadingPermission: false});
          localStorage.setItem(config().keys.user_unit_system, user.settings?.unit_system ?? 'metric');
        });
    }
  }

  async loadCompanies() {
    const filters = [
      JSON.stringify({
        field: 'does_quotes',
        value: true,
      }),
    ];
    const orderBys = [JSON.stringify({field: 'name', direction: 'ASC'})];
    this.companiesService
      ?.getCompanies({
        orderBys: orderBys,
        filters: filters,
      })
      .then(companiesApiListResponse => {
        const companies = (companiesApiListResponse.records as PaCompany[]) ?? [];

        if (!companies.length) {
          localStorage.removeItem(config().keys.current_company);
          return;
        }

        const currentCompanyId = localStorage.getItem(config().keys.current_company);
        let selectedCompany = companies.find(company => currentCompanyId === company.id);
        if (!currentCompanyId || !selectedCompany) {
          selectedCompany = companies[0];
          localStorage.setItem(config().keys.current_company, selectedCompany.id);
          localStorage.setItem(config().keys.current_role, selectedCompany.my_role);
        }

        this.setState({
          companies: companies,
        });

        MessageService.sendMessage(messages.topSelectionDataLoaded);
      })
      .catch(error => {
        localStorage.removeItem(config().keys.current_company);
        throw new Error('Error ' + error);
      });
  }

  render() {
    const {companies} = this.state;
    return (
      <div id="layout-wrapper" className="p-d-flex p-flex-column">
        <BrowserRouter>
          {this.state.isAuthenticated ? (
            <React.Fragment>
              <div id="top_toolbar" className="p-shadow-2 p-d-flex p-flex-column">
                <Toolbar className="p-p-2" left={<LeftToolbar />} right={<RightToolbar companies={companies} />} />
              </div>
              {this.state.loadingPermission === true ? (
                <div className="overlay">
                  <ProgressSpinner className="overlay-spinner" />
                </div>
              ) : this.usersService?.permissions.length !== 0 ? (
                <div id="content" className="p-m-auto">
                  <Switch>
                    <Route path="/draft/:id">
                      <NewEditDraftForm />
                    </Route>
                    <Route path="/draft">
                      <NewEditDraftForm />
                    </Route>
                    <Route path="/drafts">
                      <DraftListComponent />
                    </Route>
                    <Route path="/">
                      <DraftListComponent />
                    </Route>
                    <Route path="/:any">
                      <DraftListComponent />
                    </Route>
                  </Switch>
                </div>
              ) : (
                <div id="content" className="p-d-flex p-flex-row">
                  <div className="permission-denied-header">
                    <div className="permision-denied-title">You are not permitted to user this app.</div>
                    <br />
                    <div className="permission-denied-subtitle">Reach out to your superior, if this is incorrect.</div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ) : (
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/reset_password">
                <ResetPassword />
              </Route>
              <Route path="/">
                <Login />
              </Route>
              <Route path="/:any">
                <Login />
              </Route>
            </Switch>
          )}
        </BrowserRouter>
      </div>
    );
  }
}

export default AppFrame;
