export const messages = {
  topSelectionChanged: 'top-selection-changed',
  topSelectionDataLoaded: 'top-selection-data-loaded',
  loggedIn: 'loggedin',
  loggedOut: 'loggedout',
  draftsViewed: 'drafts-viewed',
  draftViewed: 'draft-viewed',
  draftDeleted: 'draft-deleted',
  orderCanNotBeSaved: 'order-can-not-be-saved',
  draftPrint: 'print-selected-drafts-prep',
  draftPrintInternal: 'print-selected-drafts-go',
};
