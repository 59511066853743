import React from 'react';
import CompaniesService from '../services/CompaniesService';
import DraftsService from '../services/DraftsService';
import ProductsService from '../services/ProductsService';
import {AuthService, TwoToast, UsersService} from 'two-app-ui';
import OrdersService from '../services/OrdersService';
import LocationsService from '../services/LocationsService';
import ContactsService from '../services/ContactsService';

type Made2QuoteAppContextProps = {
  authService: AuthService;
  usersService: UsersService;
  companiesService: CompaniesService;
  ordersService: OrdersService;
  draftsService: DraftsService;
  productsService: ProductsService;
  twoToast: TwoToast;
  locationsService: LocationsService;
  contactsService: ContactsService;
};

export const Made2QuoteAppContext = React.createContext<Partial<Made2QuoteAppContextProps>>({});
export default Made2QuoteAppContext;
