import {Button} from 'primereact/button';
import React, {PropsWithChildren} from 'react';
import {TwoDialog} from 'two-app-ui';

interface Props {
  showDialog: boolean;
  onNo: () => void;
  onYes: () => void;
}

export const RevertCurrentDefinitionDialog = ({showDialog, onYes, onNo}: PropsWithChildren<Props>) => {
  const footer = (
    <div className={'p-d-flex p-justify-end'}>
      <Button label="No" className={'p-mr-2 p-button-text'} onClick={onNo} />
      <Button label="Yes" onClick={onYes} />
    </div>
  );
  const content =
    'Are you sure you want to return to the original product definition revision? It will cause loss of all changes.';
  return (
    <TwoDialog
      header="Revert Current Definition"
      loading={false}
      showDialog={showDialog}
      style={{width: '50vw'}}
      breakpoints={{'768px': '75vw', '576px': '90vw'}}
      onHide={onNo}
      footer={footer}
    >
      {content}
    </TwoDialog>
  );
};
