import React from 'react';
import Made2QuoteAppContext from '../../context/Made2QuoteAppContext';
import {ScrollPanel} from 'primereact/scrollpanel';
import {Subscription} from 'rxjs';
import {MessageService, DraftsPrinterComponent, TwoToast} from 'two-app-ui';
import {messages} from '../../config/messages';
import {ApiListResponse, Draft, PaProductDefinition, QueryParameter} from 'two-core';
import DraftsService from '../../services/DraftsService';
import {ProgressSpinner} from 'primereact/progressspinner';
import {DraftCard} from './DraftCard';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './Drafts.scss';
import {faMagnifyingGlass, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import config from '../../config/config';
import {InputSwitch, InputSwitchChangeParams} from 'primereact/inputswitch';
import ProductsService from '../../services/ProductsService';
import {DeleteDraftDialog} from './DeleteDraftDialog';

interface State {
  drafts: Draft[];
  loading: boolean;
  draftDeleting: boolean;
  filters: {
    searchValue: string;
    showDone: boolean;
  };
  selectedDraft?: Draft;
  showDeleteDraftDialog: boolean;
  exportDraft: boolean;
  prodDefRevisions: Map<number, PaProductDefinition[]>;
}

class DraftListComponent extends React.Component<RouteComponentProps, State> {
  static contextType = Made2QuoteAppContext;
  subscription: Subscription = new Subscription();
  typingTimer?: NodeJS.Timeout;

  productsService?: ProductsService;
  draftsService?: DraftsService;
  twoToast?: TwoToast;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      drafts: [],
      loading: false,
      draftDeleting: false,
      filters: {
        searchValue: localStorage.getItem(config().keys.drafts_search_value) ?? '',
        showDone: localStorage.getItem(config().keys.ff2_search_value) === 'true' ? true : false,
      },
      showDeleteDraftDialog: false,
      exportDraft: false,
      prodDefRevisions: new Map<number, PaProductDefinition[]>(),
    };

    this.loadDrafts = this.loadDrafts.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onShowDoneSwitched = this.onShowDoneSwitched.bind(this);
    this.onNewDraftButtonClick = this.onNewDraftButtonClick.bind(this);
    this.onDeleteDraftDialogYes = this.onDeleteDraftDialogYes.bind(this);
    this.onDeleteDraftDialogNo = this.onDeleteDraftDialogNo.bind(this);
    this.onDraftDeleteAction = this.onDraftDeleteAction.bind(this);
    this.onDraftPrintAction = this.onDraftPrintAction.bind(this);
    this.onPrintDialogOpen = this.onPrintDialogOpen.bind(this);
  }

  async componentDidMount() {
    this.twoToast = this.context.twoToast;
    this.draftsService = this.context.draftsService;
    this.productsService = this.context.productsService;

    if (!this.subscription) {
      this.subscription = MessageService.getMessage().subscribe(async message => {
        if (message === messages.topSelectionChanged || message === messages.topSelectionDataLoaded) {
          this.loadDrafts();
        }
      });
    } else {
      this.loadDrafts();
    }

    MessageService.sendMessage(messages.draftsViewed);
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadDrafts() {
    const currentCompany = localStorage.getItem(config().keys.current_company);

    if (currentCompany) {
      const {searchValue, showDone} = this.state.filters;
      this.setState(() => ({loading: true}));
      const filters: string[] = [];

      if (!showDone) {
        filters.push(
          JSON.stringify({
            field: 'order_id',
            condition: 'isNull',
          })
        );
      } else {
        filters.push(
          JSON.stringify({
            field: 'order_id',
            condition: 'isNotNull',
          })
        );
      }
      filters.push(
        JSON.stringify({
          field: 'deleted',
          value: 'false',
        })
      );
      const orderBys = [JSON.stringify({field: 'updated_at', direction: 'DESC'})];
      const params: QueryParameter = {
        search: searchValue || searchValue.length ? searchValue : undefined,
        filters: filters,
        orderBys: orderBys,
        aggregate: false,
        page_size: 100,
        offset: 0,
      };

      this.draftsService
        ?.getDrafts(params)
        .then((data: ApiListResponse) => {
          const dataRecords = (data.records as Draft[]) ?? [];
          this.setState(() => ({
            drafts: dataRecords,
          }));
        })
        .catch(error => {
          this.twoToast?.showError('Records load failed');
          console.log(error);
        })
        .then(() => this.setState(() => ({loading: false})));
    }
  }

  async onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    await this.setState({
      filters: {
        ...this.state.filters,
        searchValue: event.target.value,
      },
    });
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      localStorage.setItem(config().keys.drafts_search_value, this.state.filters.searchValue);
      this.loadDrafts();
    }, config().stopTypingDetection);
  }

  async onShowDoneSwitched(e: InputSwitchChangeParams) {
    await this.setState({
      filters: {
        ...this.state.filters,
        showDone: e.target.value,
      },
    });

    this.typingTimer = setTimeout(() => {
      localStorage.setItem(config().keys.ff2_search_value, this.state.filters.showDone ? 'true' : 'false');
      this.loadDrafts();
    }, config().stopTypingDetection);
    this.loadDrafts();
  }

  onNewDraftButtonClick() {
    this.props.history.push('/draft');
  }

  onDeleteDraftDialogYes(draft: Draft) {
    const currentCompany = localStorage.getItem(config().keys.current_company) ?? '';
    this.setState(() => ({draftDeleting: true}));
    this.draftsService
      ?.deleteDraft(currentCompany, draft.id)
      .then(async () => {
        this.setState(() => ({
          showDeleteDraftDialog: false,
          selectedDraft: undefined,
        }));
        this.twoToast?.showSuccess('Draft deleted.');
        await this.loadDrafts();
      })
      .catch(error => {
        this.twoToast?.showError('Draft deletion failed.');
        console.log(error);
      })
      .finally(() => {
        this.setState(() => ({
          draftDeleting: false,
        }));
      });
  }

  onDeleteDraftDialogNo() {
    this.setState({
      showDeleteDraftDialog: false,
      selectedDraft: undefined,
    });
  }

  onDraftDeleteAction(draft: Draft) {
    this.setState({
      showDeleteDraftDialog: true,
      selectedDraft: draft,
    });
  }

  onDraftPrintAction(draft: Draft) {
    this.setState({
      loading: true,
    });
    if (draft.revision_id && !this.state.prodDefRevisions.has(draft.revision_id)) {
      this.productsService?.getProductsDefinitions(draft.revision_id).then((data: ApiListResponse) => {
        const prodDefs = this.state.prodDefRevisions;
        prodDefs.set(draft.revision_id!, data.records as PaProductDefinition[]);
        this.setState(
          {
            loading: false,
            exportDraft: true,
            selectedDraft: draft,
            prodDefRevisions: prodDefs,
          },
          () => {
            MessageService.sendMessage(messages.draftPrint);
          }
        );
      });
    } else {
      this.setState(
        {
          loading: false,
          exportDraft: true,
          selectedDraft: draft,
        },
        () => {
          MessageService.sendMessage(messages.draftPrint);
        }
      );
    }
  }

  onPrintDialogOpen() {
    this.setState({
      exportDraft: false,
    });
  }

  render() {
    const {
      drafts,
      loading,
      filters,
      showDeleteDraftDialog,
      selectedDraft,
      exportDraft,
      prodDefRevisions,
      draftDeleting,
    } = this.state;

    const draftsScrollPanelContent = loading ? (
      <ProgressSpinner className="overlay-spinner" />
    ) : (
      drafts.map(draft => (
        <DraftCard
          key={draft.id}
          draft={draft}
          onDraftDelete={this.onDraftDeleteAction}
          onDraftPrint={this.onDraftPrintAction}
        />
      ))
    );

    return (
      <>
        <div id="drafts_page">
          <div className="drafts-content p-m-2">
            <div className="drafts-search">
              <div className="p-inputgroup">
                <InputText
                  name="searchValue"
                  className="p-inputtext-sm"
                  placeholder="Search"
                  value={filters.searchValue}
                  onChange={this.onSearchChange}
                />
                <Button className="p-button-sm" icon={<FontAwesomeIcon icon={faMagnifyingGlass} />} />
                <div className="p-grid p-col-3 p-m-0 p-p-0">
                  <span className="p-col-6 p-pt-2 text-right p-pr-2">FF2</span>
                  <div className="p-col-6 p-pt-1 p-pl-0">
                    <InputSwitch
                      name="showDone"
                      checked={filters.showDone}
                      onChange={e => this.onShowDoneSwitched(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <ScrollPanel className="p-mt-1 drafts-scroll-panel">{draftsScrollPanelContent}</ScrollPanel>
          </div>
          {!loading && (
            <Button
              className="p-button-rounded add-draft-button p-shadow-5"
              icon={<FontAwesomeIcon icon={faPlus} size="2x" />}
              onClick={this.onNewDraftButtonClick}
            />
          )}
        </div>
        {!!selectedDraft && showDeleteDraftDialog && (
          <DeleteDraftDialog
            showDialog={showDeleteDraftDialog}
            onNo={this.onDeleteDraftDialogNo}
            onYes={this.onDeleteDraftDialogYes}
            draft={selectedDraft}
            draftDeleting={draftDeleting}
          />
        )}
        {!!selectedDraft && exportDraft && (
          <DraftsPrinterComponent
            drafts={[selectedDraft]}
            productDefinitionRevisions={prodDefRevisions}
            triggerMessage={messages.draftPrint}
            onPrintDialogOpen={this.onPrintDialogOpen}
          />
        )}
      </>
    );
  }
}

export default withRouter(DraftListComponent);
