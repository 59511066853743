import {Button} from 'primereact/button';
import React, {PropsWithChildren} from 'react';
import {TwoDialog} from 'two-app-ui';

interface Props {
  title: string;
  showDialog: boolean;
  onHide: () => void;
}

export const ValidationDialog = (props: PropsWithChildren<Props>) => {
  const footer = <Button label="Close" className={'p-mr-2 p-button-text'} onClick={props.onHide} />;
  return (
    <TwoDialog
      headerTitle={props.title}
      loading={false}
      onHide={props.onHide}
      showDialog={props.showDialog}
      footer={footer}
      style={{width: '50vw'}}
      breakpoints={{'768px': '75vw', '576px': '90vw'}}
    >
      {props.children}
    </TwoDialog>
  );
};
