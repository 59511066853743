import {Button} from 'primereact/button';
import React, {PropsWithChildren, useCallback} from 'react';
import {TwoDialog} from 'two-app-ui';
import {Draft} from 'two-core';

interface Props {
  showDialog: boolean;
  onNo: () => void;
  onYes: (draft: Draft) => void;
  draft: Draft;
  draftDeleting: boolean;
}

export const DeleteDraftDialog = (props: PropsWithChildren<Props>) => {
  const onYes = useCallback(() => {
    props.onYes(props.draft);
  }, []);

  const footer = (
    <div className={'p-d-flex p-justify-end'}>
      <Button label="No" className={'p-mr-2 p-button-text'} onClick={props.onNo} disabled={props.draftDeleting} />
      <Button label="Yes" onClick={onYes} loading={props.draftDeleting} />
    </div>
  );
  const content = `Are you sure you want to delete the draft ${props.draft.id} ${props.draft.reference}`;
  return (
    <TwoDialog
      headerTitle="Delete?"
      loading={false}
      showDialog={props.showDialog}
      style={{width: '50vw'}}
      breakpoints={{'768px': '75vw', '576px': '90vw'}}
      onHide={props.onNo}
      footer={footer}
    >
      {content}
    </TwoDialog>
  );
};
