import React, {useCallback, useRef} from 'react';
import {Draft} from 'two-core';
import './DraftCard.scss';
import {Card} from 'primereact/card';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {TieredMenu} from 'primereact/tieredmenu';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEllipsis, faTrashAlt} from '@fortawesome/pro-solid-svg-icons';
import {Button} from 'primereact/button';
import {MenuItemCommandParams, MenuItemOptions} from 'primereact/menuitem';
import {AppMenuItem, AppMenuItemTemplate} from 'two-app-ui';
import {faPrint} from '@fortawesome/pro-regular-svg-icons';

interface Props {
  draft: Draft;
  onDraftDelete: (draft: Draft) => void;
  onDraftPrint: (draft: Draft) => void;
}

export const DraftCard = withRouter((props: RouteComponentProps & Props) => {
  const {draft, history} = props;
  const menuRef = useRef<TieredMenu>(null);
  const onDraftClick = useCallback(() => {
    history.push(`/draft/${draft.id}`);
  }, []);

  const onMenuButtonClick = useCallback(e => {
    e.stopPropagation();
    menuRef.current?.toggle(e);
  }, []);

  const menuItems: AppMenuItem[] = [
    {
      label: 'Print',
      faIcon: faPrint,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: (e: MenuItemCommandParams) => {
        e.originalEvent.stopPropagation();
        props.onDraftPrint(props.draft);
        menuRef.current?.toggle(e.originalEvent);
      },
    },
  ];
  if (!props.draft.order_id) {
    menuItems.push({
      label: 'Delete',
      faIcon: faTrashAlt,
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: (e: MenuItemCommandParams) => {
        e.originalEvent.stopPropagation();
        props.onDraftDelete(props.draft);
        menuRef.current?.toggle(e.originalEvent);
      },
    });
  }

  return (
    <div onClick={onDraftClick}>
      <Card className="draft-card p-my-2">
        <div className="p-grid p-m-0">
          <div className="p-col-4 p-grid p-m-0">
            <div className="p-col-12 p-p-0 p-as-center">{draft.id}</div>
            {draft.order_id ? (
              <div className="p-col-12 p-p-0 p-as-center x-small-label">FF2: {draft.order_id}</div>
            ) : (
              <></>
            )}
          </div>
          <div className="p-col-6 p-py-0 p-pr-0 p-pl-1 p-as-center">
            <div className="p-col-12 p-p-0 p-as-center">{draft.reference}</div>
          </div>
          <div className="p-col-2 p-py-0 p-pr-0 p-pl-1">
            <TieredMenu model={menuItems} popup ref={menuRef} />
            <div className="p-d-flex h-100 w-100">
              <div className="p-ml-auto p-as-center">
                <Button className="p-button-icon-only p-ml-auto" onClick={onMenuButtonClick}>
                  <FontAwesomeIcon icon={faEllipsis} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
});
